svg {
    padding-top: 1vw;
    opacity: 0;
    transition: opacity 1s;
}

.active {
    cursor: pointer;
    opacity: 1;
}

span {
    opacity: 0;
    transition: opacity 1s;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 0.7vw;
    font-weight: bold;
    position: absolute;
    right: 7vw;
    top: 0.5vw;
    padding: 0.1vw 0.3vw;
    text-align: center;
    color: teal;
    background-color: white;
    border-radius: 10vw;
}