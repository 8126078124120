.detailContainer {
    display: flex;
    justify-content: center;
}

.detailText {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 0.8vw;
    text-align: center;
    padding: 5vw 0 5vw 0;
    width: 20vw;
}

.itemDetailCard {
    display: flex;
    flex-direction: column;
    width: 20vw;
}

.itemDetailCard h2 {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1vw;
    text-align: center;
}

.detailPrecio {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 0.8vw;
    font-weight: bold;
    text-align: center;
}

.itemDetailImg {
    margin: auto;
}

.itemDetailCard img {
    width: 15vw;
}

.finCompra {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 0.8vw;
    width: 8vw;
    border-radius: 8px;
    outline: none;
    border: none;
    padding: 0.3vw;
    background-color: teal;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 0.5vw;
}

.seguirCompra {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 0.8vw;
    width: 8vw;
    border-radius: 8px;
    outline: none;
    border: none;
    padding: 0.3vw;
    background-color: blue;
    font-weight: bold;
    cursor: pointer;
    color: white;
}