.addContainer {
    display: flex;
    flex-direction: column;
    width: 6vw;
    margin: auto;
}

.counterContainer{
    display: flex;
    margin: auto;
}

.counterContainer p {
    margin: 2px 0 6px 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    font-size: 0.7vw;
    width: 1.4vw;
    text-align: center;
}

.counterbtn {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 0.8vw;
    background-color: teal;
    border: none;
    border-radius: 15px;
    width: 1.2vw;
    cursor: pointer;
    outline-style: none;
    margin: auto;
}

.addbtn {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 0.8vw;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    padding: 0.3vw 0.5vw;
    margin: auto auto 0.5vw auto;
    outline: none;
}