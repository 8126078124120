.itemCard {
    display: flex;
    flex-direction: column;
    margin-left: 1.5vw;
    margin-bottom: 1vw;
    border: solid 2px black;
    border-radius: 1vw;
    text-decoration: none;
    color: black;
}

.itemCard h2 {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.1vw;
    text-align: center;
    width: 15vw;
    height: 3.2vw;
    font-weight: bold;
}

.itemCard p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.1vw;
    text-align: center;
    width: 15vw;
    font-weight: bold;
}

.itemImg {
    width: 7vw;
    height: 5vw;
    margin: auto;
}

.itemCard img {
    width: 7vw;
}

.itemCard:hover {
    box-shadow: black 0 0 0.4vw;
}