.title {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 2.5vw;
    text-align: center;
    font-style: italic;
    color: teal;
    margin-top: 0.5vw;
}

.carritoContainer {
    display: flex;
    flex-direction: column;
}

.listCarrito {
    margin: auto;
    list-style: none;
}

.itemCart {
    display: flex;
    border: solid 1px black;
    padding: 0.4vw;
    justify-content: space-between;
    width: 60vw;
    height: 4.3vw;
    margin-bottom: 0.3vw;
}

.itemCart img {
    width: 4vw;
    margin: auto 0;
}

.itemCart p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 0.9vw;
    font-weight: bold;
    margin: auto 0;
}

#itemNombre {
width: 21vw;
}

#itemCantidad {
    width: 21vw;
}

#itemCantidadCheckout {
    width: 26vw;
}

.btnEliminar {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 0.8vw;
    border-radius: 7px;
    outline: none;
    border: none;
    background-color: red;
    padding: 0.3vw;
    color: white;
    cursor: pointer;
    margin: auto 0.3vw;
}

.cartTotal {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 2vw;
    font-weight: bold;
    border-top: solid black 1px;
    margin: 1vw auto;
}

.btnComprar {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 0.8vw;
    font-weight: bold;
    border-radius: 15px;
    outline: none;
    border: none;
    background-color: green;
    color: white;
    padding: 0.5vw;
    cursor: pointer;
    margin: 1.5vw auto;
}

.vaciarCarrito {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 0.8vw;
    font-weight: bold;
    border-radius: 15px;
    outline: none;
    border: none;
    background-color: red;
    color: white;
    padding: 0.5vw;
    cursor: pointer;
    margin: 1vw auto;
}

.carritoVacio {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: center;
}

.backToList {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    border-radius: 15px;
    outline: none;
    border: none;
    background-color: blue;
    color: white;
    padding: 0.5vw;
    cursor: pointer;
    margin: auto;
}