nav {
    display: flex;
    background-color: #7a2239;
}

.nombre {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1vw;
    color: white;
    padding-left: 0.5vw;
    width: 11vw;
    cursor: pointer;
    text-decoration: none;
}

nav .menu {
    display: flex;
    margin: auto;
}

.categories {
    color: white;
    padding-left: 1.5vw;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 0.9vw;
    text-decoration: none;
}

.categories:hover {
    color: teal;
}

.active {
    color:teal;
}